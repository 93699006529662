import React, { Component } from "react";
import "assets/css/style.css";
import { Modal, ModalHeader, ModalBody} from "reactstrap";

class ConvocationForm extends Component {
  state = {
    studentName: "",
    studentId: "",
    mobileNumber: "",
    branch: "",
    kurtaSize: "",
    pajamaSize: "",
    studentEmail: "",
    personalEmail: "",
    fathersName: "",
    dateOfBirth: "",
    arrivalDateTime: "",
    departureDateTime: "",
    numberOfPersons: "",
    sizeChartOpen: false,
  };

  // Handle input changes
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  // Toggle Size Chart Modal
  toggleSizeChart = () => {
    this.setState({ sizeChartOpen: !this.state.sizeChartOpen });
  };


  // Handle form submission
  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      studentName,
      studentId,
      mobileNumber,
      branch,
      kurtaSize,
      pajamaSize,
      studentEmail,
      personalEmail,
      fathersName,
      dateOfBirth,
      arrivalDateTime, 
      departureDateTime,
      numberOfPersons,
    } = this.state;

    const mobileNumberRegex = /^\d{10}$/;
    if (!mobileNumberRegex.test(mobileNumber)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    const studentEmailRegex = /^2020[a-zA-Z]{4}\d{4}@iiitkota\.ac\.in$/i; 
    if (!studentEmailRegex.test(studentEmail)) {
      alert(
        "Please enter a valid student email address"
      );
      return;
    }

    if (studentEmail.toLowerCase() === personalEmail.toLowerCase()) {
      alert("Personal email should not be the same as student email");
      return;
    }

    try {
      const response = await fetch("https://admin-panel-new.onrender.com/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studentName,
          studentId,
          mobileNumber,
          branch,
          kurtaSize,
          pajamaSize,
          studentEmail,
          personalEmail,
          fathersName,
          dateOfBirth,
          arrivalDateTime,
          departureDateTime, 
          numberOfPersons,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Form submitted successfully!");
        this.setState({
          studentName: "",
          studentId: "",
          mobileNumber: "",
          branch: "",
          kurtaSize: "",
          pajamaSize: "",
          studentEmail: "",
          personalEmail: "",
          fathersName: "",
          dateOfBirth: "",
          arrivalDateTime: "",
          departureDateTime: "", 
          numberOfPersons: "",
        });
      } else {
        alert("Submission failed: " + data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.isFormOpen} toggle={this.props.toggleForm}>
        <ModalHeader toggle={this.props.toggleForm}>
          Submit Your Details
        </ModalHeader>
        <ModalBody>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="studentName">Student's Name</label>
              <input
                type="text"
                className="form-control"
                id="studentName"
                placeholder="Enter your name"
                value={this.state.studentName}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="fathersName">Father's Name</label>
              <input
                type="text"
                className="form-control"
                id="fathersName"
                placeholder="Enter your father's name"
                value={this.state.fathersName}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <input
                type="date"
                className="form-control"
                id="dateOfBirth"
                value={this.state.dateOfBirth}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="studentId">Student's ID</label>
              <input
                type="text"
                className="form-control"
                id="studentId"
                placeholder="Enter your student ID"
                value={this.state.studentId}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="mobileNumber">Mobile Number</label>
              <input
                type="tel"
                className="form-control"
                id="mobileNumber"
                placeholder="Enter your mobile number"
                value={this.state.mobileNumber}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="branch">Branch</label>
              <input
                type="text"
                className="form-control"
                id="branch"
                placeholder="Enter your branch"
                value={this.state.branch}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="studentEmail">Student's Email</label>
              <input
                type="email"
                className="form-control"
                id="studentEmail"
                placeholder="Enter your student email"
                value={this.state.studentEmail}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="personalEmail">Personal Email</label>
              <input
                type="email"
                className="form-control"
                id="personalEmail"
                placeholder="Enter your personal email"
                value={this.state.personalEmail}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column " style={{ flex: 1, marginRight: '10px' }}>
                  <label htmlFor="kurtaSize">Kurta Size</label>
                  <select
                    className="form-control"
                    id="kurtaSize"
                    value={this.state.kurtaSize}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select Kurta Size
                    </option>
                    <option value="36">36</option>
                    <option value="38">38</option>
                    <option value="40">40</option>
                    <option value="42">42</option>
                    <option value="44">44</option>
                    <option value="46">46</option>
                  </select>
                </div>

                <div className="d-flex flex-column" style={{ flex: 1, marginRight: '10px' }}>
                  <label htmlFor="pajamaSize">Pajama Size</label>
                  <select
                    className="form-control"
                    id="pajamaSize"
                    value={this.state.pajamaSize}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select Pajama Size
                    </option>
                    <option value="36">36</option>
                    <option value="38">38</option>
                    <option value="40">40</option>
                    <option value="42">42</option>
                    <option value="44">44</option>
                    <option value="46">46</option>
                  </select>
                </div>

                <div className="d-flex flex-column align-items-center" style={{ flex: 1 }}>
                  <label>&nbsp;</label> {/* Empty label for alignment */}
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={this.toggleSizeChart}
                  >
                    Size Chart
                  </button>
                </div>
              </div>
            </div>


            {/* Size Chart Modal */}
            <Modal
              isOpen={this.state.sizeChartOpen}
              toggle={this.toggleSizeChart}
            >
              <ModalHeader toggle={this.toggleSizeChart}>Size Chart</ModalHeader>
              <ModalBody>
              <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                      <tr>
                      <th colSpan={8}>KURTA SIZE CHART</th>
                      </tr>
                      <tr>
                        <th>Size</th>
                        <th>Length (in)</th>
                        <th>Chest (in)</th>
                        <th>Shoulder (in)</th>
                        <th>Hip (in)</th>
                        <th>Sleeve (in)</th>
                        <th>Neck (in)</th>
                        <th>Waist (in)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>36</td>
                        <td>42</td>
                        <td>40</td>
                        <td>16.5</td>
                        <td>41</td>
                        <td>24</td>
                        <td>15.5</td>
                        <td>40</td>
                      </tr>
                      <tr>
                        <td>38</td>
                        <td>42</td>
                        <td>42</td>
                        <td>17.5</td>
                        <td>43</td>
                        <td>24</td>
                        <td>16</td>
                        <td>42</td>
                      </tr>
                      <tr>
                        <td>40</td>
                        <td>42</td>
                        <td>44</td>
                        <td>18.5</td>
                        <td>45</td>
                        <td>25</td>
                        <td>16.5</td>
                        <td>44</td>
                      </tr>
                      <tr>
                        <td>42</td>
                        <td>42</td>
                        <td>46</td>
                        <td>19.5</td>
                        <td>47</td>
                        <td>25</td>
                        <td>17</td>
                        <td>46</td>
                      </tr>
                      <tr>
                        <td>44</td>
                        <td>42</td>
                        <td>48</td>
                        <td>20.5</td>
                        <td>49</td>
                        <td>26</td>
                        <td>17.5</td>
                        <td>48</td>
                      </tr>
                      <tr>
                        <td>46</td>
                        <td>42</td>
                        <td>50</td>
                        <td>21.5</td>
                        <td>51</td>
                        <td>26</td>
                        <td>18</td>
                        <td>50</td>
                      </tr>
                    </tbody>
                  </table>

                  <br/>

                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th colSpan={7}>PAJAMA SIZE CHART</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>SIZE</td>
                        <td>36</td>
                        <td>38</td>
                        <td>40</td>
                        <td>42</td>
                        <td>44</td>
                        <td>46</td>
                      </tr>
                      <tr>
                        <td>LENGTH</td>
                        <td>40</td>
                        <td>40</td>
                        <td>42</td>
                        <td>42</td>
                        <td>42</td>
                        <td>42</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
              </ModalBody>
            </Modal>
            

            <div className="form-group">
              <label htmlFor="arrivalDateTime">Date and Time of Arrival</label>
              <input
                type="datetime-local"
                className="form-control"
                id="arrivalDateTime"
                value={this.state.arrivalDateTime}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="departureDateTime">Date and Time of Departure</label>
              <input
                type="datetime-local"
                className="form-control"
                id="departureDateTime"
                value={this.state.departureDateTime}
                onChange={this.handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="numberOfPersons">Number of Person(s) Accompanied</label>
              <input
                type="number"
                className="form-control"
                id="numberOfPersons"
                placeholder="Enter number of persons accompanied"
                value={this.state.numberOfPersons}
                onChange={this.handleChange}
                required
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

export default ConvocationForm;
