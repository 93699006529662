import React from "react";

import { Container, Row, Col, CardBody, Card } from "reactstrap";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
// import Calenderjs from "views/HeaderSection/Academics/calendar/calendar";
import Helmet from "react-helmet";
class Calender extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Calender - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Calender Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Calender, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Calender IIIT Kota,Academic Calender,Academic Calender IIIT Kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Academic Calendar</b>
                  </h2>
                </Col>
              </Row>
              {/* <Calenderjs /> */}
            </Container>
            <Container className="mt-5">
              <Row className="row-grid text-center justify-content-center">
                {/* <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/Academic%20Calendar.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/Academic%20Calendar.pdf">
                          Click here to download the latest Calender
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col> */}
                {/* <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/calendar/1st_Year_2021-22.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/calendar/1st_Year_2021-22.pdf">
                          Click here to download the latest Calender for 1st year (UG).
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col> */}
                {/* <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/Academic%20calendar%201st%20year.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/Academic%20calendar%201st%20year.pdf">
                          Click here to download the latest Calender for 1st
                          Year.
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col> */}
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/calendar/Academic_Calender_&_Teaching_days_for_Even_semester_2024-25.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/calendar/Academic_Calender_&_Teaching_days_for_Even_semester_2024-25.pdf">
                          UG & PG Calendar for Even Semester
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/calendar/PG Academic Calendar for Odd Semester.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                         <a href="https://files.iiitkota.ac.in/pdf/calendar/PG Academic Calendar for Odd Semester.pdf">
                           PG Calendar for Odd Semesters (M.tech and PhD) 
                          </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="row-grid text-center justify-content-center">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/calendar/Calender%20V-aug23.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/calendar/Calender%20V-aug23.pdf">
                          Click here to download the latest Calendar for 5th
                          Semester UG.
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col> */}
                {/* <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/calendar/Calender%20VIII-jan24.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/calendar/Calender%20VIII-jan24.pdf">
                          Click here to download the latest Calendar for 8th
                          Semester UG.
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
              {/* <Row className="row-grid text-center justify-content-center">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/PG%20Academic%20Calender%20Even%20Sem%202022-23.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/calendar/ACADEMIC_CALENDER_FOR_I_SEM_PG_STUDENTS.pdf">
                          Click here to download the latest Calendar for Odd
                          semester new students (PG).
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="https://files.iiitkota.ac.in/pdf/PG%20Academic%20Calender%20Even%20Sem%202022-23.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/ACADEMIC%20CALENDER%20FOR%20CONTINUING%20PG%20STUDENTS.pdf">
                          Click here to download the latest Calendar for Odd semester 
                          continuing students (PG).
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Calender;
