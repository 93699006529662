import React from "react";
import "assets/css/style.css";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import {Modal, ModalHeader, ModalBody } from "reactstrap";
import ConvocationForm from "components/convocationform";

class Convocation extends React.Component {
  state = {
    isFormOpen: false, // State to toggle the form modal
  };
  
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  toggleForm = () => {
    this.setState({ isFormOpen: !this.state.isFormOpen });
  };

  render() {
    return (
      <>
        <Helmet>
          <title>
            Convocation - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Courses Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Courses, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Courses in IIIT Kota,CSE in IIIT Kota,ECE in IIIT Kota, CSE,ECE,Computer Science and Engineering in IIIT Kota,Computer Science and Engineering, Electromnincs and Communication Engineering in IIIT Kota, Electromnincs and Communication Engineering,1st Year Cse,1ST YEAR ECE,2ND YEAR CSE,2ND YEAR ECE,
            3RD YEAR CSE,3RD YEAR ECE,4TH YEAR CSE,4TH YEAR ECE"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">


<Container>
  <Row className="justify-content-center text-center mb-5">
    <Col lg="15">
      <h2>
        <b>Convocation</b>
      </h2>
    </Col>
  </Row>

  {/* Cards Section */}
  <div className="accordion" id="convocationAccordion">
    {/* Card 1 */}
    <div className="card mb-4 rounded shadow-sm">
    <div
      className="card-header d-flex justify-content-between align-items-center rounded-top"
      id="headingOne"
        style={{ cursor: "pointer" }}
        data-toggle="collapse"
        data-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
        onClick={(e) => {
          document.querySelectorAll('.collapse-arrow').forEach(arrow => {
            arrow.style.transform = 'rotate(0deg)';
          });
          const arrow = e.currentTarget.querySelector(".collapse-arrow");
          const isOpen = e.currentTarget.getAttribute("aria-expanded") === "true";
          arrow.style.transform = isOpen ? "rotate(180deg)" : "rotate(0deg)";
        }}
      >
         <div className="d-flex align-items-center">
        <span className="font-weight-bold">
          4th Convocation
        </span>
      {/*  <span
            className="ml-3 px-2 py-1 bg-warning text-dark font-weight-bold rounded"
            style={{ fontSize: "0.9rem" }}
          >
            18th September 2024
          </span> */}
        </div>
        <i
          className="fas fa-chevron-down collapse-arrow"
          style={{
            transition: "transform 0.3s ease",
            transform: "rotate(180deg)",
          }}
        ></i>
      </div>

      <div
        id="collapseOne"
        className="collapse show"
        aria-labelledby="headingOne"
        data-parent="#convocationAccordion"
      >
        <div className="card-body">

  <div className="table-responsive">
    <table className="table table-bordered">
      <thead>
        <tr className="text-center align-middle">
          <th width="8%">Sr No.</th>
          <th colSpan="2">Title</th>
          <th width="17%">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center align-middle">1</td>
          <td width="40%">
            Tentative list of students eligible for award of degree (details in
            English and Hindi)
          </td>
          <td width="40%">
            <a
              href="https://files.iiitkota.ac.in/pdf/convocation/Students List UG_updated on _18_01_2025.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Students List UG (Updated on 18/01/2025)
            </a>
              <br/>
            <a
              href="https://files.iiitkota.ac.in/pdf/convocation/Students List PG_updated on _16_12_2024.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Students List PG
            </a>
            
          </td>
          <td className="text-center align-middle">16/12/2024</td>
        </tr>
        <tr>
          <td className="text-center align-middle">2</td>
          <td width="40%">Detailed information regarding the 4th Annual Convocation</td>
          <td width="40%">
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>{" "}
            (Link to be updated soon)
          </td>
          <td className="text-center align-middle">--</td>
        </tr>
        <tr>
          <td className="text-center align-middle">3</td>
          <td width="40%">List of the Gold Medalists</td>
          <td width="40%">
            <a
              href="https://files.iiitkota.ac.in/pdf/convocation/List of the Gold Medalists.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>

          </td>
          <td className="text-center align-middle">16/12/2024</td>
        </tr>
        <tr>
          <td className="text-center align-middle">4</td>
          <td width="40%">Brochure</td>
          <td width="40%">
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>{" "}
            (Link to be updated soon)
          </td>
          <td className="text-center align-middle">--</td>
        </tr>
      </tbody>
    </table>
  </div>

 {/* <ul className="mt-3">
    <li>
      <a href="#" target="_blank" rel="noopener noreferrer">
        Students List UG (Updated till 01-03-2024)
      </a>
    </li>
  </ul> */}

<div className="d-flex justify-content-center">
  <button className="btn btn-primary mt-3" onClick={this.toggleForm}>
    Fill in the Details
  </button>
</div>

</div>
<ConvocationForm 
  isFormOpen={this.state.isFormOpen} 
  toggleForm={this.toggleForm} 
/>




      </div>
    </div>

    {/* Card 2 */}
    <div className="card mb-4 rounded shadow-sm">
    <div
      className="card-header d-flex justify-content-between align-items-center rounded-top"
      id="headingTwo"
    style={{ cursor: "pointer" }}
    data-toggle="collapse"
    data-target="#collapseTwo"
    aria-expanded="false"
    aria-controls="collapseTwo"
    onClick={(e) => {
      document.querySelectorAll('.collapse-arrow').forEach(arrow => {
        arrow.style.transform = 'rotate(0deg)';
      });
      const arrow = e.currentTarget.querySelector(".collapse-arrow");
      const isOpen = e.currentTarget.getAttribute("aria-expanded") === "true";
      arrow.style.transform = isOpen ? "rotate(180deg)" : "rotate(0deg)";
    }}
  >
    <span className="font-weight-bold ">
      3rd Convocation
    </span>
    <i
          className="fas fa-chevron-down collapse-arrow"
          style={{
            transition: "transform 0.3s ease",
            transform: "rotate(0deg)",
          }}
        ></i>
  </div>

  <div
    id="collapseTwo"
    className="collapse"
    aria-labelledby="headingTwo"
    data-parent="#convocationAccordion"
  >
    <div className="card-body">
      {/* Responsive YouTube iframe */}
      <div className="embed-responsive embed-responsive-16by9 mb-3">
        <iframe
          className="embed-responsive-item"
          src="https://www.youtube.com/embed/_QYoGK3Xod4?si=b1ApFMvW6YNkApN_"
          title="3rd Convocation IIIT KOTA"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <ul>
        <li className="h5">
          Convocation 2024 Photos
          <a
            href="https://drive.google.com/drive/folders/1iBUjzjzGv-plY7_RspeSSHhh8qZF08S3?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2 font-weight-bold"
          >
            Click Here
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>


    {/* Card 3 */}
    <div className="card mb-4 rounded shadow-sm">
    <div
      className="card-header d-flex justify-content-between align-items-center rounded-top"
      id="headingThree"
        style={{ cursor: "pointer" }}
        data-toggle="collapse"
        data-target="#collapseThree"
        aria-expanded="false"
        aria-controls="collapseThree"
        onClick={(e) => {
          document.querySelectorAll('.collapse-arrow').forEach(arrow => {
            arrow.style.transform = 'rotate(0deg)';
          });
          const arrow = e.currentTarget.querySelector(".collapse-arrow");
          const isOpen = e.currentTarget.getAttribute("aria-expanded") === "true";
          arrow.style.transform = isOpen ? "rotate(180deg)" : "rotate(0deg)";
        }}
      >
        <span className="font-weight-bold ">2nd Convocation</span>
        <i
          className="fas fa-chevron-down collapse-arrow"
          style={{
            transition: "transform 0.3s ease",
            transform: "rotate(0deg)",
          }}
        ></i>
      </div>

      <div
        id="collapseThree"
        className="collapse"
        aria-labelledby="headingThree"
        data-parent="#convocationAccordion"
      >
        <div className="card-body">
        <div className="embed-responsive embed-responsive-16by9 mb-3">
        <iframe
                  width="956"
                  height="538"
                  src="https://www.youtube.com/embed/jrrRpUhOajo?si=VZx_RUExsXeAU_1e"
                  title="2nd Convocation IIIT KOTA"
                  // frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                </div>
        </div>
      </div>
    </div>

   
  </div>
</Container>




          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Convocation;
